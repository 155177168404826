import StringHelper from '../../utils/StringHelper';

function MountFilter(payload) {
    try {
        const filter = {
            tenant: payload.tenant,
            vinculo: payload.despesa ? ['funcionario', 'fornecedor'] : '',
        };

        if (payload.nameToSearchBase) {
            const nameToSearch = payload.nameToSearchBase.replace(/[^\d\w]+/g, '');
            if (!isNaN(nameToSearch)) {
                const cpf = StringHelper.formatToCpf(nameToSearch);
                const cnpj = StringHelper.formatToCnpj(nameToSearch);


                filter.doc1 = cpf;
                filter.doc2 = cnpj;
            } else {
                filter.nome = payload.nameToSearchBase;
                filter.email = payload.nameToSearchBase;
            }
        }
        return filter;
    } catch (e) {
        console.log(e);
    }
}

export default MountFilter;