import React, { Suspense} from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import indexRoutes from './routes/index.jsx';
import LazyLoadingView from './views/LazyLoading/LazyLoadingView.jsx';
import { AuthProvider } from './context/AuthContext';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss';
import './assets/demo/demo.css';
import './assets/attivilog/custom.css';
import './assets/attivilog/tailwindcss.css';
import './assets/attivilog/receitas.scss';
import './assets/attivilog/boletos.scss';

const hist = createBrowserHistory();

ReactDOM.render(
  <AuthProvider>
    <div className="top-element">
      <NotificationContainer />
    </div>
    <Router history={hist}>
      <Suspense fallback={<LazyLoadingView />}>
        <Switch>
          {indexRoutes.map((prop, key) => <Route path={prop.path} key={key} component={prop.component} />)}
        </Switch>
      </Suspense>
    </Router>
    </AuthProvider>,
  document.getElementById('root'),
);
