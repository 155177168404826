import React, { useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [token, setTokenContext] = useState(Cookies.get('FINANCEIRO_TOKEN'));
  const [username, setUsernameContext] = useState(Cookies.get('USERNAME'));
  const [fullname, setFullnameContext] = useState(Cookies.get('USUARIO_PAINEL'));
  const [companies, setCompaniesContext] = useState(Cookies.getJSON('COMPANIES_PAINEL'));
  const [selectedCompanies, setSelectedCompaniesContext] = useState(Cookies.getJSON('SELECTED_COMPANIES'));
  const [tenant, setTenantContext] = useState(Cookies.get('TENANT_PAINEL'));

  return (
    <AuthContext.Provider value={{
      token,
      setTokenContext,
      username,
      setUsernameContext,
      fullname,
      setFullnameContext,
      companies,
      setCompaniesContext,
      selectedCompanies,
      setSelectedCompaniesContext,
      tenant,
      setTenantContext,
    }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
