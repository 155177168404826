class StringHelper {
  static limiter = (str, num) => {
    if (str.length <= num) {
      return str
    };
    return str.slice(0, num) + '...';
  }

  static captalize(string) {
    const array = string.split(' ');
    let capitalizeString = array.map(element => {
      const element1 = element.toLowerCase().slice(1);
      const element2 = element.charAt(0).toUpperCase();
      return element2 + element1;
    });
    return capitalizeString.join(' ');
  }

  static formatToCpf(string) {
    let cpf = string;
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  static formatToCnpj(string) {
    let cnpj = string;
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
    return cnpj;
  }

  static defaultMoneyFormat (string) {
    return string.replace(/(\.|\s)|(,)/g,(m,p1,p2) => p1 ? "" : ".");
  }
}

export default StringHelper;