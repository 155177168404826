import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';

export default function DefaultModal(props) {  
  DefaultModal.defaultProps = {
    headerIcon: null,
    toggle: null,
    classNameBody: '',
  };
  DefaultModal.propTypes = {
    className: PropTypes.string.isRequired,
    classNameBody: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    headerName: PropTypes.string.isRequired,
    headerIcon: PropTypes.element,
    children: PropTypes.element.isRequired,
  };

  return (
    <Modal
      className={props.className}
      isOpen={props.isOpen}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>
        {props.headerName}
        {' '}
        {props.headerIcon}
      </ModalHeader>
      <ModalBody
        className={props.classNameBody}
      >
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>Fechar</Button>
      </ModalFooter>
    </Modal>
  );
}
