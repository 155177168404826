import React, { useState, useRef, useEffect, useMemo } from "react";
import Cookies from 'js-cookie';
import { Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import Notification from '../../components/Notifications/Notification';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Api from '../../config/Api';
import logo from 'cargozilla.svg';
import DefaultModal from "components/DefaultModal/DefaultModal";
import RecoveryPassword from "./RecoveryPassword";
import useAuth from '../../context/useAuth';

export default function Login(props){
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [loginErrorCount, setLoginErrorCount] = useState(0);
  const [captchaIsValid, setCaptchIsValid] = useState(true);
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [api, setApi] = useState(null);
  const {
    token: tokenApi,
    setTokenContext,
    setUsernameContext,
    setFullnameContext,
    setCompaniesContext,
    setSelectedCompaniesContext,
    setTenantContext,
  } = useAuth();
  const captchaRef = useRef(null);
  const siteKey = process.env.REACT_APP_HCAPTCHA_SITEKEY;

  useMemo(() => {
    const apiInstance = new Api(tokenApi);
    setApi(apiInstance);
  }, [tokenApi]);

  const onLoad = () => {
    setCaptchIsValid(false);
  };

  useEffect(() => {
    if (token) {
      setCaptchIsValid(true);
    }
  }, [token]);

  const abrirNotificacaoErro = (message, title) => Notification
    .createNotification('error', message, title);

  const abrirNotificacaoSucesso = (title) => Notification.createNotification('success', title);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    await clearOldCookies();
    const { data } = await api.login(username, password);

    
    if (data.token) {
      setTokenContext(data.token);
      setUsernameContext(data.username);
      setFullnameContext(data.fullname);
      setCompaniesContext(data.companies);
      setSelectedCompaniesContext([data.companies[0]]);

      Cookies.set('USERNAME', username, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      Cookies.set('FINANCEIRO_TOKEN', data.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      Cookies.set('USUARIO_PAINEL', data.fullname, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

      if(data.companies && data.companies.length) {
        Cookies.set('COMPANIES_PAINEL', data.companies, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        Cookies.set('SELECTED_COMPANIES', [data.companies[0]], { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      }

      if (data.tenants && data.tenants.length) {
        const [tenant] = data.tenants;
        setTenantContext(tenant.name);
        Cookies.set('TENANT_PAINEL', tenant.name, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      }

      const pathname = getRedirectionURL(data.modulePermissions);
      setLoginErrorCount(0);
      props.history.push({
        pathname,
        state: { modulePermissions: data.modulePermissions }
      });
    } else if (data.code) {
      abrirNotificacaoErro('Nome de usuário ou senha incorretos');
      setLoginErrorCount(loginErrorCount + 1);
      setLoading(false);
    } else {
      abrirNotificacaoErro('Este usuário não existe');
      setLoginErrorCount(loginErrorCount + 1);
      setLoading(false);
    }

    setLoading(false);
  }

  const getDefaultRedirectUrl = (modulePermissions) => {
    const financeiroPermissions = modulePermissions.find(m => m.name === 'financeiro');
    if (financeiroPermissions) {
      const dashboardPermission = financeiroPermissions.actions.find((a) => a === 'dashboard');
      if (dashboardPermission) {
        return '/dashboard';
      }

      return '/despesas-abertas';
    }
    return '/login';
  }

  const hasPreviousUrl = (state) => {
    if (state && state.previousURL && state.previousURL !== '/') {
      return true;
    }
    return false;
  }

  const getRedirectionURL = (modulePermissions) => {
    try {
      const defaultRedirect = getDefaultRedirectUrl(modulePermissions);
      if (hasPreviousUrl(props.location.state)) {
        return props.location.state.previousURL;
      }
      return defaultRedirect;
    } catch (error) {
      console.log(error);
    }
  }

  const clearOldCookies = async () => {
    await Promise.all([
      Cookies.remove('FINANCEIRO_TOKEN', { domain: process.env.REACT_APP_COOKIE_DOMAIN }),
      Cookies.remove('PESSOA_SEARCH', { domain: process.env.REACT_APP_COOKIE_DOMAIN }),
    ]);
  }

  const toggleRecoveryModal = () => {
    setRecoveryModal(!recoveryModal);
  };

  return (
    <>
      <div className="wrapper background-attivilog">
        <div className="center-panel background-white">
          {!loading ? (
            <>
              <div className="login-logo">
                <div className="app-login">
                  <div className="logo-img">
                    <img src={logo} alt="react-logo" className="logo-login" />
                  </div>
                </div>
              </div>
              <p className="login-text">Acesse sua conta</p>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Row md="12">
                    <Label for="username">Login</Label>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      value={username}
                      placeholder="Digite seu nome de usuário"
                      onChange={(e) => setUsername(e.target.value)}
                      required
                      minLength="3"
                      disabled={loading}
                    />
                  </Row>
                  <Row>
                    <Label for="password">Senha</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Digite sua senha"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      minLength="6"
                      disabled={loading}
                    />
                  </Row>
                  {loginErrorCount > 2 && (
                    <HCaptcha
                      sitekey={siteKey}
                      onLoad={onLoad}
                      onVerify={setToken}
                      ref={captchaRef}
                    />
                  )}
                  <Button
                    className="w-100"
                    color="success"
                    type="submit"
                    disabled={loading || !captchaIsValid}
                  >
                    Entrar
                  </Button>
                  <button
                    type="button"
                    className="link-forgot-password"
                    onClick={() => toggleRecoveryModal()}
                  >
                    Esqueceu sua senha?
                  </button>
                </FormGroup>
              </Form>
            </>
          ) : (
            <>
              <Spinner className="spinner-login" color="success" />
              <p>Entrando</p>
            </>
          )}
        </div>
      </div>
      <DefaultModal
        className="new-expense-modal"
        isOpen={recoveryModal}
        toggle={toggleRecoveryModal}
        headerName="Recuperar Senha"
      >
        <RecoveryPassword
          username={username}
          abrirNotificacaoErro={abrirNotificacaoErro}
          abrirNotificacaoSucesso={abrirNotificacaoSucesso}
          toggleRecoveryModal={toggleRecoveryModal}
          api={api}
        />
      </DefaultModal>
    </>
  );
}
