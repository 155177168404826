import React, { Component } from 'react';
import AsyncSelectOriginal from 'react-select/async';
import PropTypes from 'prop-types';

export default class AsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectData: [],
      currentOffset: 0,
      haveMore: true,
    };
  }

  componentDidMount() {
    this.getMore();
  }

  handleChange = (option) => {
    this.props.onChange(option);
  };

  handleInputChange = (inputValue) => {
    if (inputValue.key !== 'Backspace') {
      return null;
    }
    this.setState(() => ({
      currentOffset: 0,
      haveMore: true,
    }), async () => {
      const options = await this.props.loadOptions(0, null);
      const labelFn = this.props.labelFunction
        ? this.props.labelFunction
        : (option) => option[this.props.labelToUse || 'nome'];
      const optionsConcat = options.map((option) => {
        return {
          value: option[this.props.idToUse],
          label: this.props.customIconOptions ? this.handleCustomLabel(option)
            : labelFn(option),
          info: { ...option },
        }
      });
      this.setState({
        currentOffset: options.length,
        selectData: optionsConcat,
      });
    });
  };

  getMore = async (inputValue) => {
    let paramToSearch = null;
    let { currentOffset, haveMore } = this.state;
    const { selectData } = this.state;
    if (!haveMore && (inputValue === '' || inputValue === undefined)) {
      return selectData;
    }

    if (inputValue !== '' && inputValue != null && inputValue.length !== 0 && inputValue !== undefined) {
      this.setState({ currentOffset: 0 });
      currentOffset = 0;
      paramToSearch = inputValue;
    }

    if (this.props.minInputAmount && inputValue
      && this.props.minInputAmount > inputValue.length) {
      return [{
        value: 0,
        label: `Digite no mínimo ${this.props.minInputAmount} caracteres para iniciar a busca.`
      }];
    }

    const options = await this.props.loadOptions(currentOffset, paramToSearch);
    let optionsConcat;

    if (options && options.length === 0) {
      this.setState({ haveMore: false });
      haveMore = false;
    }
    const labelFn = this.props.labelFunction
    ? this.props.labelFunction
    : (option) => option[this.props.labelToUse || 'nome'];
    optionsConcat = options.map((option) => {
      return {
        value: option[this.props.idToUse || 'id'],
        label: this.props.customIconOptions ? this.handleCustomLabel(option)
        : labelFn(option),
        info: { ...option },
      }
    });
    if (currentOffset > 0) {
      optionsConcat = optionsConcat.concat(selectData);
    }
    this.setState({
      currentOffset: currentOffset + options.length,
      selectData: optionsConcat,
    });
    return optionsConcat;
  }

  handleCustomLabel = (option) => {
    return (
      <div className="flex">
        {this.props.handleStatusCol(option, true)}
        {this.props.labelFunction(option)}
      </div>
    );
  }

  render() {
    let valueToShow = '';
    if (this.props.defaultOption && this.props.defaultOption.label !== undefined) {
      valueToShow = {
        label: this.props.defaultOption.label,
        value: this.props.defaultOption.value,
      };
    }
    return (
      <>
        <AsyncSelectOriginal
          id={this.props.id}
          name={this.props.name}
          className={this.props.readOnly ? 'disabled-select' : ''}
          onChange={this.handleChange}
          required={!!this.props.require}
          isDisabled={this.props.isDisabled}
          placeholder={this.props.placeholder || 'Selecione'}
          defaultOptions={this.state.selectData}
          loadOptions={this.getMore}
          value={this.props.isMulti ? this.props.defaultOptions : valueToShow}
          onMenuScrollToBottom={() => this.getMore()}
          onKeyDown={(e) => this.handleInputChange(e)}
          loadingMessage={() => 'Buscando....'}
          noOptionsMessage={() => 'Não encontramos :('}
          isClearable={this.props.isClearable || true}
          isMulti={this.props.isMulti}
        />
      </>
    );
  }
}

AsyncSelect.defaultProps = {
  idToUse: 'id',
  labelToUse: 'nome',
  defaultOption: null,
};

AsyncSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  idToUse: PropTypes.string,
  labelToUse: PropTypes.string,
  defaultOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
};
