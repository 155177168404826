import React, { useState } from "react";
import Cookies from 'js-cookie';
import { FormGroup, Button } from 'reactstrap';
import AsyncSelect from '../AsyncSelect/AsyncSelect';
import MountFilter from '../AsyncSelect/MountFilter';
import mediaQuery from '../../utils/MediaQueryHelper';
import Notification from '../Notifications/Notification';


function SearchPerson(props) {
  const { tenant } = props;
  const [pessoaSelected, setPessoaSelected] = useState(null);
  const getPessoas = async (offset, nameToSearch) => {
    if (tenant) {
      const payload = {
        tenant,
        offset,
        nameToSearchBase: nameToSearch,
        email: nameToSearch,
      }
      const filter = await MountFilter(payload);
      const pessoas = await props.api.getPessoas(10, offset, filter);
      return pessoas.data.rows;
    }
    return [];
  }

  const abrirNotificacaoInfo = (message) => Notification
    .createNotification('info', message)

  const searchPerson = (pessoa) => {
    if (pessoaSelected) {
      Cookies.set('PESSOA_SEARCH', JSON.stringify(pessoaSelected), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      const win = window.open("/pessoas", "_blank");
      win.focus();
      setPessoaSelected('')
    } else {
      abrirNotificacaoInfo('Uma pessoa deve ser selecionada.');
    }
  }

  const handleStatusCol = (pessoa, customStyle) => {
    const styleObj = { height: "20px", marginTop: "2%", marginRight: "10px" };
    if (pessoa.inativar) {
      return (
        <span className="badge font-badge badge-attivilog-red" style={customStyle && styleObj}>
          Inativo
        </span>
      )
    }
    return (
      <span className="badge badge-success font-badge" style={customStyle && styleObj}>
        Ativo
      </span>
    )
  }

  const matchesWidth = mediaQuery('(min-width: 700px)');
  const checkPathToRender = () => {
    if (window.location.pathname === '/pessoas') {
      return false;
    }
    if (window.location.pathname === '/') {
      return false;
    }
    if (!matchesWidth) {
      return false;
    }
    return true;
  }

  const handleSelectChange = (evt) => {
    setPessoaSelected(evt);
  }

  return (
    <>
      {checkPathToRender() && (
        <>
          <FormGroup className="search-person-select">
            <AsyncSelect
              className={props.detail ? 'disabled-select' : ''}
              id="nomeOficial"
              name="nomeOficial"
              awaitDefault={!!props.pessoaSelected}
              onChange={handleSelectChange}
              loadOptions={getPessoas}
              idToUse="id"
              defaultOption={pessoaSelected
                ? pessoaSelected
                : true}
              placeholder="Busca por Pessoa"
              minInputAmount={3}
              labelFunction={(pessoa) => pessoa && (pessoa.cnpj || pessoa.cpf)
                ? `${pessoa.nomeOficial} - ${(pessoa.cnpj || pessoa.cpf)}`
                : pessoa.nomeOficial
              }
              customIconOptions
              handleStatusCol={handleStatusCol}
            />
          </FormGroup>
          <Button
            color="primary"
            onClick={() => searchPerson()}
            className="header-search-button"
          >
            <i className="fas fa-search" />
          </Button>
        </>
      )}
    </>
  )
}

export default SearchPerson;
