import React from 'react';


// Lazy Loading Routes
const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const Conta = React.lazy(() => import('../views/Contas/Contas'));
const MovimentacaoBancaria = React.lazy(() =>
  import('../views/Contas/MovimentacaoBancaria')
);
const ReceitasAbertas = React.lazy(() =>
  import('../views/Receitas/ReceitasAbertas')
);
const ReceitasInadimplestes = React.lazy(() =>
  import('../views/Receitas/ReceitasInadimplentes')
);
const ReceitasLiquidadas = React.lazy(() =>
  import('../views/Receitas/ReceitasLiquidadas')
);
const Pessoas = React.lazy(() => import('../views/Pessoas/Pessoas'));
const Convenios = React.lazy(() => import('../views/Convenios/Convenios'));
const Faturamento = React.lazy(() =>
  import('../views/Faturamento/Faturamento')
);
const Boletos = React.lazy(() => import('../views/Boletos/LotesBoleto'));
const ContasOrcamentarias = React.lazy(() =>
  import('../views/ContasOrcamentarias/ContasOrcamentarias')
);
const CentrosDeCustos = React.lazy(() =>
  import('../views/CentrosDeCusto/CentrosDeCusto')
);
const RelatorioReceita = React.lazy(() =>
  import('../views/Relatorios/Receita/RelatorioReceita')
);
const RelatorioDespesa = React.lazy(() =>
  import('../views/Relatorios/Despesa/RelatorioDespesa')
);
const Ufs = React.lazy(() => import('../views/Ufs/Ufs'));
const Cidades = React.lazy(() => import('../views/Cidades/Cidades'));
const DespesasAbertas = React.lazy(() =>
  import('../views/Despesas/DespesasAbertas')
);
const DespesasInadimplentes = React.lazy(() =>
  import('../views/Despesas/DespesasInadimplentes')
);
const DespesasLiquidadas = React.lazy(() =>
  import('../views/Despesas/DespesasLiquidadas')
);
const RelatorioConhecimento = React.lazy(() =>
  import('../views/Relatorios/Conhecimento/RelatorioConhecimento')
);
const GrupoFaturamento = React.lazy(() =>
  import('../views/GrupoFaturamento/GrupoFaturamento')
);
const GrupoPessoa = React.lazy(() =>
  import('../views/GrupoPessoa/GrupoPessoa')
);
const MotivoBloqueio = React.lazy(() =>
  import('../views/MotivoBloqueio/MotivoBloqueio')
);
const BloqueioConhecimento = React.lazy(() =>
  import('../views/BloqueioConhecimento/BloqueioConhecimento')
);
const RelatorioDRE = React.lazy(() =>
  import('../views/Relatorios/DRE/RelatorioDRE')
);
const AgrupamentosDemonstacao = React.lazy(() =>
  import('../views/AgrupamentosDemonstacao/AgrupamentosDemonstacao')
);
const EnquadramentoConta = React.lazy(() =>
  import('../views/EnquadramentoConta/EnquadramentoConta')
);
const ConhecimentosLiquidacao = React.lazy(() =>
  import('../views/ConhecimentosLiquidacao/ConhecimentosLiquidacao')
);
const RelatorioMovimentacoes = React.lazy(() =>
  import('../views/Relatorios/Movimentacoes/RelatorioMovimentacoes')
);
const Plano = React.lazy(() => import('../views/Planos/Planos'));
const Conciliacao = React.lazy(() =>
  import('../views/Conciliacao/Conciliacao')
);
const FaturamentoPorPlano = React.lazy(() =>
  import('../views/Faturamento/FaturamentoPorPlano/FaturamentoPorPlano')
);
const Arquivos = React.lazy(() =>
  import('../views/ArquivosBancarios/ArquivosBancarios')
);
const CriarMultiplasDespesas = React.lazy(() =>
  import('../views/CriarMultiplasDespesas/CriarMultiplasDespesas')
);
const RelatorioPagamentos = React.lazy(() =>
  import('../views/Relatorios/Pagamentos/RelatorioPagamentos')
);
const ProtocoloFatura = React.lazy(() =>
  import('../views/ProtocoloFatura/ProtocoloFatura')
);
const FaturamentoPorDisponibilidade = React.lazy(() =>
  import(
    '../views/Faturamento/FaturamentoPorDisponibilidade/FaturamentoPorDisponibilidade'
  )
);
const MensagensEmail = React.lazy(() =>
  import('../views/MensagemEmail/MensagensEmail')
);
const RelatorioPessoas = React.lazy(() =>
  import('views/Relatorios/Pessoas/RelatorioPessoas')
);
const RelatorioDesconto = React.lazy(() =>
  import('../views/Relatorios/Desconto/RelatorioDesconto')
);
const IndicadoresSaas = React.lazy(() =>
  import('../views/IndicadoresSaas/IndicadoresSaas')
);
const NotaFiscalServico = React.lazy(() =>
  import('../views/NotaFiscalServico/NotaFiscalServico')
);
const RelatorioNotasFiscais = React.lazy(() =>
  import('../views/Relatorios/NotasFiscais/RelatorioNotasFiscais')
);
const EmailTemplate = React.lazy(() =>
  import('../views/EmailTemplate/EmailTemplate')
);
const ReguaCobranca = React.lazy(() =>
  import('../views/ReguaCobranca/ReguaCobranca')
);
const BloqueioCliente = React.lazy(() =>
  import('../views/BloqueioCliente/BloqueioCliente')
);

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    permission: 'dashboard',
    icon: 'fas fa-chart-pie',
    visible: true,
    subRoutes: [
      {
        path: '/dashboard',
        name: 'Financeiro',
        permission: 'dashboard',
        icon: 'fas fa-chart-pie',
        component: Dashboard,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/indicadores-saas',
        name: 'Indicadores SaaS',
        permission: 'dashboard',
        icon: 'fas fa-chart-pie',
        component: IndicadoresSaas,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/cadastros',
    name: 'Cadastros',
    icon: 'fas fa-th-list',
    visible: true,
    subRoutes: [
      {
        path: '/contas',
        name: 'Contas',
        permission: 'conta',
        icon: 'fas fa-money-check',
        component: Conta,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/pessoas',
        name: 'Pessoas',
        permission: 'pessoa',
        icon: 'fas fa-id-badge',
        component: Pessoas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/convenios',
        name: 'Convenio',
        permission: 'convenio',
        icon: 'fas fa-wallet',
        component: Convenios,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/contas-orcamentarias',
        name: 'Contas Orçamentárias',
        permission: 'conta-orcamentaria',
        icon: 'fas fa-list',
        component: ContasOrcamentarias,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/centros-de-custos',
        name: 'Centro de Custo',
        permission: 'centro-de-custo',
        icon: 'fas fa-street-view',
        component: CentrosDeCustos,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/ufs',
        name: 'Estados',
        permission: 'uf',
        icon: 'fas fa-globe-americas',
        component: Ufs,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/cidades',
        name: 'Cidades',
        permission: 'cidade',
        icon: 'fas fa-city',
        component: Cidades,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/mensagens-email',
        name: 'Mensagens de Email',
        permission: 'cidade',
        icon: 'fas fa-envelope',
        component: MensagensEmail,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/email-template',
        name: 'E-mail Template',
        permission: 'email-template',
        icon: 'fas fa-envelope-open-text',
        component: EmailTemplate,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/regua-cobranca',
        name: 'Régua de Cobrança',
        permission: 'regua-cobranca',
        icon: 'fas fa-ruler',
        component: ReguaCobranca,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/bloqueio-cliente',
        name: 'Bloqueio de Cliente',
        permission: 'bloqueio-cliente',
        icon: 'fas fa-user-lock',
        component: BloqueioCliente,
        visible: true,
        subRoutes: null,
      },      
      {
        path: '/grupo-faturamento',
        name: 'Grupos Faturamento',
        permission: 'grupo-faturamento',
        icon: 'fas fa-users',
        component: GrupoFaturamento,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/grupo-pessoa',
        name: 'Grupos Pessoa',
        permission: 'grupo-pessoa',
        icon: 'fas fa-user-friends',
        component: GrupoPessoa,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/motivo-bloqueio',
        name: 'Motivo Bloqueio',
        permission: 'motivo-bloqueio',
        icon: 'fas fa-ban',
        component: MotivoBloqueio,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/agrupamentos-demonstracao',
        name: 'Agrupamentos Demonstração',
        permission: 'agrupamento-demonstracao',
        icon: 'fas fa-sitemap',
        component: AgrupamentosDemonstacao,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/enquadramento-conta',
        name: 'Enquadramento Conta',
        permission: 'enquadramento-conta',
        icon: 'fas fa-align-left',
        component: EnquadramentoConta,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/planos',
        name: 'Planos',
        permission: 'enquadramento-conta',
        icon: 'fas fa-stream',
        component: Plano,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/receitas',
    name: 'Receitas',
    icon: 'fas fa-money-check-alt',
    visible: true,
    subRoutes: [
      {
        path: '/receitas-abertas',
        name: 'Abertas',
        permission: 'receita',
        icon: 'fas fa-money-check-alt',
        component: ReceitasAbertas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/receitas-inadimplentes',
        name: 'Inadimplentes',
        permission: 'receita',
        icon: 'fas fa-thumbs-down',
        component: ReceitasInadimplestes,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/receitas-liquidadas',
        name: 'Liquidadas',
        permission: 'receita',
        icon: 'fas fa-check',
        component: ReceitasLiquidadas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/liquidar-conhecimentos',
        name: 'Liquidar Conhecimentos',
        permission: 'receita',
        icon: 'fas fa-hand-holding-usd',
        component: ConhecimentosLiquidacao,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/despesas',
    name: 'Despesas',
    permission: 'despesa',
    icon: 'fas fa-file-invoice-dollar',
    visible: true,
    subRoutes: [
      {
        path: '/despesas-abertas',
        name: 'Abertas',
        permission: 'despesa',
        icon: 'fas fa-file-invoice-dollar',
        component: DespesasAbertas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/despesas-inadimplentes',
        name: 'Inadimplentes',
        permission: 'despesa',
        icon: 'fas fa-thumbs-down',
        component: DespesasInadimplentes,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/despesas-liquidadas',
        name: 'Liquidadas',
        permission: 'despesa',
        icon: 'fas fa-check',
        component: DespesasLiquidadas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/multiplas-despesas',
        name: 'Múltiplas Despesas',
        permission: 'despesa',
        icon: 'fas fa-check-double',
        component: CriarMultiplasDespesas,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/movimentacao-bancaria',
    name: 'Extrato',
    permission: 'movimentacao-bancaria',
    icon: 'fas fa-list',
    component: MovimentacaoBancaria,
    visible: true,
    subRoutes: null,
  },
  {
    path: '/faturamento',
    name: 'Faturamento',
    permission: 'faturamento',
    icon: 'fas fa-search-dollar',
    visible: true,
    subRoutes: [
      {
        path: '/faturar',
        name: 'Faturar',
        permission: 'faturamento',
        icon: 'fas fa-funnel-dollar',
        component: Faturamento,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/faturar-plano',
        name: 'Faturar Planos',
        permission: 'faturamento',
        icon: 'fas fa-file-invoice',
        component: FaturamentoPorPlano,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/faturar-disponibilidade',
        name: 'Faturar Disponibilidade',
        permission: 'faturamento',
        icon: 'fas fa-truck-loading',
        component: FaturamentoPorDisponibilidade,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/bloquear-documento',
        name: 'Bloquear Documento',
        permission: 'faturamento',
        icon: 'fas fa-ban',
        component: BloqueioConhecimento,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/financeiro',
    name: 'Financeiro',
    permission: 'faturamento',
    icon: 'fas fa-sort-numeric-up-alt',
    visible: true,
    subRoutes: [
      {
        path: '/conciliacao-bancaria',
        name: 'Conciliação Bancária',
        permission: 'faturamento',
        icon: 'fas fa-sort-numeric-up-alt',
        component: Conciliacao,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/arquivos',
        name: 'Arquivos',
        permission: 'faturamento',
        icon: 'fas fa-file-invoice-dollar',
        component: Arquivos,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/protocolo-fatura',
        name: 'Protocolo Fatura',
        permission: 'faturamento',
        icon: 'fas fa-file-archive',
        component: ProtocoloFatura,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/boletos',
    name: 'Boletos',
    permission: 'boleto',
    icon: 'fas fa-barcode',
    component: Boletos,
    visible: true,
    subRoutes: null,
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    icon: 'far fa-file-excel',
    visible: true,
    subRoutes: [
      {
        path: '/receita-relatorio',
        name: 'Receitas',
        permission: 'receita-relatorio',
        icon: 'fas fa-file-invoice',
        component: RelatorioReceita,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/despesa-relatorio',
        name: 'Despesas',
        permission: 'despesa-relatorio',
        icon: 'fas fa-folder-minus',
        component: RelatorioDespesa,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/conhecimento-relatorio',
        name: 'Conhecimentos',
        permission: 'conhecimento-relatorio',
        icon: 'fas fa-donate',
        component: RelatorioConhecimento,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/pagamentos-relatorio',
        name: 'Pagamentos',
        permission: 'pagamento-relatorio',
        icon: 'fas fa-receipt',
        component: RelatorioPagamentos,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/movimentacoes-relatorio',
        name: 'Movimentações',
        permission: 'movimentacao-relatorio',
        icon: 'fas fa-file-medical-alt',
        component: RelatorioMovimentacoes,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/dre-relatorio',
        name: 'D.R.E.',
        permission: 'dre-relatorio',
        icon: 'fas fa-balance-scale',
        component: RelatorioDRE,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/pessoas-relatorio',
        name: 'Pessoas',
        permission: 'pessoa',
        icon: 'fas fa-id-badge',
        component: RelatorioPessoas,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/desconto-relatorio',
        name: 'Descontos',
        permission: 'pessoa',
        icon: 'fas fa-hand-holding-dollar',
        component: RelatorioDesconto,
        visible: true,
        subRoutes: null,
      },
      {
        path: '/notas-fiscais',
        name: 'Notas Fiscais de Serviço',
        permission: 'nfs-relatorio',
        icon: 'fas fa-hand-holding-dollar',
        component: RelatorioNotasFiscais,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    path: '/documentos',
    name: 'Documentos',
    permission: 'nota-servico',
    icon: 'fas fa-file-alt',
    visible: true,
    subRoutes: [
      {
        path: '/documentos/nota-servico',
        name: 'Nota Fiscal de Serviço',
        icon: 'fas fa-file-contract',
        permission: 'nota-servico',
        component: NotaFiscalServico,
        visible: true,
        subRoutes: null,
      },
    ],
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard',
    permission: 'dashboard',
  },
];
export default dashRoutes;
