import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import Cookies from 'js-cookie';
import ApiControleUsuario from '../../config/ApiControleUsuario';
import { AuthContext } from '../../context/AuthContext';

import logo from 'cargozilla.svg';

let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      openCadastros: false,
      expanded: false,
      visibilityClass: 'hide-element',
      shouldRenderHoverPanel: false,
      selectedRouteIndex: 0,     
    }
  }
  
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? 'active' : '';
  }

  componentDidMount() {
    this.api = new ApiControleUsuario(this.context.token);
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    window.addEventListener('resize', () => this.updateMenuState());
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }

    window.removeEventListener('resize', () => this.updateMenuState());
  }

  updateMenuState = () => {
    const isExpanded = Cookies.get('SIDEBAR_EXPANDED');
    const minWidthForCollapsedMenu = 991;
    const hasMinWidthForCollapse = window.innerWidth <= minWidthForCollapsedMenu;

    if (!this.state.isExpanded && isExpanded === 'false' && hasMinWidthForCollapse) {
      this.toogleMenu();
    }
  }

  toogleExpanded = (route) => {
    route.expanded = !route.expanded;
    this.forceUpdate();
  }

  getArrowState(route) {
    let rotation = 'arrow-rotate-0';

    if (route.expanded)
      rotation = 'arrow-rotate-minus-90';

    return rotation;
  }

  createUserHistory = async (menu, itemMenu, keySubRoute, key) => {
      try {
        if (keySubRoute !== 0 || key !== 0) {
          await this.api.createUserHistory({
            username: this.context.username,
            productName: 'financeiro',
            menu: menu.name,
            item: itemMenu.name,
            icon: menu.icon,
            path: menu.path,
            permission: menu.permission,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

  toogleMenu() {
    Cookies.set('SIDEBAR_EXPANDED', !this.state.expanded, { domain: process.env.REACT_APP_COOKIE_DOMAIN })
    this.props.toogleCollapse(!this.state.expanded);

    let visibilityClass = 'show-element';
    if (this.state.expanded) {
      visibilityClass = 'hide-element';
    }

    this.setState({
      expanded: !this.state.expanded,
      visibilityClass
    });
  }

  onMouseEnter = (item, idx) => (evt) => {
    if (this.state.expanded) {
      return;
    }
    this.setState({
      shouldRenderHoverPanel: true,
      selectedRouteIndex: idx,
    })
  }

  onMouseLeave = item => (evt) => {
    if (this.state.expanded) {
      return;
    }
    this.setState({
      shouldRenderHoverPanel: false,
    })
  }

  renderParentRoute = (route, key) => {
    return (
      <li className={`nav-parent ${this.state.expanded ? '' : 'nav-parent-collapsed'}`}
        onMouseEnter={this.onMouseEnter(route, key)}
        onMouseLeave={this.onMouseLeave(route)}>
        <button onClick={() => this.toogleExpanded(route)} className="primary-background">
          <i className={route.icon} />
          <p className={this.state.visibilityClass}>{route.name}</p>
          <i id="arrow" className={`fas fa-caret-left  ${this.getArrowState(route)} ${this.state.visibilityClass}`}></i>
        </button>
        {this.state.expanded &&
          <ul>
            {route.expanded &&
              route.subRoutes.map((subRoute, keySubRoute) => {
                return (
                  < li className={this.activeRoute(subRoute.path) + (subRoute.pro ? ' active-pro' : '')}
                    key={key}

                  >
                    <NavLink
                      to={subRoute.path}
                      className="nav-link"
                      onClick={() => this.createUserHistory(subRoute, route, keySubRoute, key)}
                    >
                      <i className={subRoute.icon} />
                      <p className={this.state.visibilityClass}>{subRoute.name}</p>
                    </NavLink>
                  </li>
                )
              })}
          </ul>
        }
        {!this.state.expanded && this.state.shouldRenderHoverPanel && key === this.state.selectedRouteIndex &&
          <div className='routes-panel'>
            <div className="routes-group">
              <p>{route.name}</p>
              <div className="subroutes-separator"></div>
              <ul className="subroutes-group">
                {route.subRoutes.map((subRoute, keySubRoute) => {
                  return (
                    < li key={keySubRoute}>
                      <NavLink className="subroute-link" to={subRoute.path} onClick={() => this.createUserHistory(subRoute, route, keySubRoute, key)}>
                        <p>{subRoute.name}</p>
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div >
        }
      </li>
    )
  }

  render() {
    return (
      <>
        <div className={`primary-background ${this.state.expanded ? 'expanded-sidebar' : 'collapsed-sidebar'} sidebar`}
          data-active-color={this.props.activeColor}>
          <div className="logo">
            <div className="app-home">
              <a
                href="/"
                className="simple-text logo-mini"
              >
                <div className="logo-img">
                  <img src={logo} alt="react-logo" className="logo-attivilog" />
                </div>
              </a>
              <a
                href="/"
                className={`${this.state.visibilityClass} simple-text logo-normal`}
              >
                Cargozilla
            </a>
            </div>
            <div className={`${this.state.visibilityClass} ${this.state.expanded ? '' : 'hide-tenant-home'} tenant-home`}>
              <a href={`http://${this.context.tenant}.attivilog.com.br`} >
                <i className="fas fa-truck color-secondary"></i>
              </a>
            </div>
            {this.state.expanded &&
              <div className="menu-collapse" onClick={() => this.toogleMenu()}>
                <i className="fas fa-angle-double-left shrink-button"></i>
              </div>
            }
            {!this.state.expanded &&
              <div className="menu-expand" onClick={() => this.toogleMenu()}>
                <i className="fas fa-angle-double-right shrink-button"></i>
              </div>
            }
          </div>
          <div className={`sidebar-wrapper sidebar-scroll ${this.state.expanded ? 'expanded-sidebar' : 'collapsed-sidebar'}`}
            ref="sidebar">
            <Nav>
              {this.props.routes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <React.Fragment key={`${key} + _main_routes`}>
                    {prop.visible && prop.subRoutes !== null && (
                      prop.subRoutes !== null && (
                        this.renderParentRoute(prop, key)
                      )
                    )}

                    {prop.visible && prop.subRoutes === null && (
                      < li className={this.activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')}
                        key={key}
                        onMouseEnter={this.onMouseEnter(prop, key)}
                        onMouseLeave={this.onMouseLeave(prop)}
                      >
                        <NavLink
                          to={prop.path}
                          className={`nav-link ${this.state.expanded ? '' : 'nav-parent-collapsed'}`}
                          activeClassName="active"
                        >
                          <i className={prop.icon} />
                          <p className={this.state.visibilityClass}>{prop.name}</p>
                        </NavLink>
                        {this.state.shouldRenderHoverPanel && key === this.state.selectedRouteIndex &&
                          <div className='routes-panel routes-panel-normal-group'>
                            <NavLink
                              to={prop.path}
                              className="collapsed-nav-link"
                              activeClassName="active"
                            >
                              <p>{prop.name}</p>
                            </NavLink>
                          </div >
                        }
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </Nav>
          </div>
        </div >
      </>
    );
  }
}

export default Sidebar;

Sidebar.contextType = AuthContext;