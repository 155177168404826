import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import dashboardRoutes from "routes/dashboard.jsx";
import Api from '../../config/Api';
import { AuthContext } from '../../context/AuthContext';

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      allowedRoutes: [],
      canRender: false,
      isExpanded: false,
    }
  }

  componentDidMount() {
    this.api = new Api(this.context.token);
    if (this.context.token) {
      this.getModulePermissions();
    } else {
      this.props.history.push({
        pathname: '/login',
        state: { previousURL: this.props.location.pathname }
      });
    }

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }

  getModulePermissions = async () => {
    try {
      let permissions = [];
      if (this.props.location.state) {
        permissions = this.props.location.state.modulePermissions;
      } else {
        const tokenInfo = (await this.api.validateToken(this.context.token)).data;
        permissions = tokenInfo.modulePermissions;
      }

      const financeiroPermission = permissions.find((p) => {
        return p.name === 'financeiro';
      });

      const routeToShow = dashboardRoutes.filter((route) => {
        if (route.subRoutes) {
          const subRouteToShow = route.subRoutes.filter((subRoute) => {
            return this.routeHasPermission(financeiroPermission, subRoute.permission);
          });

          if (subRouteToShow.length > 0) {
            route.subRoutes = subRouteToShow;
            return true;
          }
        }

        return this.routeHasPermission(financeiroPermission, route.permission);
      });
      this.setState({
        allowedRoutes: routeToShow,
        canRender: true
      });
    } catch (err) {
      console.log(err);
      this.props.history.push('/login');
    }
  }

  routeHasPermission = (permissions, route) => {
    if (route === '/')
      return true;

    return permissions.actions.some((a) => {
      return route === a;
    });
  }

  toogleCollapse = (isExpanded) => {
    this.setState({ isExpanded });
  }

  render() {
    return (
      <div className={this.state.canRender ? `wrapper` : 'hide'}>
        <Sidebar
          {...this.props}
          routes={this.state.allowedRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          toogleCollapse={this.toogleCollapse}
        />
        <div className={`main-panel ${this.state.isExpanded ? '' : 'collapse-panel'}`} ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {this.state.allowedRoutes.map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              if (prop.subRoutes) {
                return prop.subRoutes.map((propSubRoute, subKey) => {
                  return <Route path={propSubRoute.path} component={propSubRoute.component} key={subKey} />
                });
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;

Dashboard.contextType = AuthContext;