import React from 'react';
import {
  Row, Col, Card, CardHeader, CardTitle, CardBody, Spinner,
} from 'reactstrap';

export default function LazyLoadingView() {
  return (
    <>
      <div className="custom-content rotas-content">
        <Row className="h-100">
          <Col xs={12} className="h-100">
            <Card className="h-100">
              <CardHeader className="small-space-title">
                <CardTitle className="header-row">
                  <Col md="3">
                    <h4>Loading</h4>
                    <Spinner type="grow" color="primary" />
                  </Col>
                  <Col md="6" className="header-row" />
                </CardTitle>
              </CardHeader>
              <CardBody />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}