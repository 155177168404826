import axios from "axios";
const backendApi = process.env.REACT_APP_FINANCEIRO_BACK_URL;

export default class Api {
  constructor(token, onError) {
    this.axios = axios.create({
      baseURL: backendApi,
      headers: {
        Authorization: token,
      },
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  login(username, password) {
    return this.axios.post("/user/login", { username, password });
  }

  validateToken(token) {
    return this.axios.post("/validate-token", { token });
  }

  getUserModulePermission() {
    return this.axios.get("/user/module-permissions");
  }

  getUserViewPermission(view) {
    return this.axios.get(`/user/view-permissions/${view}`);
  }

  sendConfirmationCodeEmail(username) {
    return this.axios.post("/user/send-confirmation-code-email", { username });
  }

  validateConfirmationCode(username, code) {
    return this.axios.post("/user/validate-confirmation-code", {
      username,
      code,
    });
  }

  recoveryUsernamePassword(username, newPassword) {
    return this.axios.put("/user/recovery-username-password", {
      username,
      newPassword,
    });
  }

  //Receitas
  async getIncomes(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/receita/abertas-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...filters,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  async getMissingPaymentIncomes(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/receita/inadimplentes-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...Api.removeBlankFields(filters),
            getAllIncomes: filters.getAllIncomes,
            period: filters.period,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  async getPaidIncomes(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/receita/liquidadas-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...filters,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  async atualizarBoletoReceita(id) {
    return this.axios.get(`receita/boleto/integracao-api/${id}`);
  }

  async atualizarBoletosLote(incomes) {
    return this.axios.post(`receita/boleto/integracao-api/`, {
      incomes,
    });
  }

  async gerarBoletoAvulso(incomes) {
    return this.axios.post(`receita/boleto/integracao-api-avulso`, incomes);
  }

  getSingleIncome(id) {
    return this.axios.get(`/receita/completa/${id}`);
  }

  getReceitaCompleta(id) {
    return this.axios.get(`/receita/completa/${id}`);
  }

  getReceitaConhecimentos(id) {
    return this.axios.get(`/receita/conhecimentos/${id}`);
  }

  cancelarReceita(id) {
    return this.axios.put(`/receita/cancelar/${id}`);
  }

  searchIncomes(payload) {
    return this.axios.post(`/receita/grid/search`, {
      dataInicio: payload.dataVencimentoInicio,
      dataFim: payload.dataVencimentoFim,
      sacadoId: payload.sacadoId,
      loteFaturamento: payload.loteFaturamento,
      fatura: payload.numeroFatura,
      tenant: this.state.tenant,
    });
  }

  postReceitaFaturamento(faturas) {
    return this.axios.post(`/receita/faturamento`, faturas);
  }

  enviarEmailReceita(receita) {
    return this.axios.post(`/receita/enviar-email`, receita);
  }

  sendMultipleDocuments(receitas) {
    return this.axios.post("/receita/enviar-multiplos-emails", receitas);
  }

  receitaLiquidarParcial(id, payload) {
    return this.axios.put(`/receita/liquidar-parcial/${id}`, {
      valor: payload.valor,
      dtMovimentacao: payload.dtMovimentacao,
      historico: payload.historico,
      contaId: payload.contaId,
      tenant: payload.tenant,
    });
  }

  receitaLiquidarPorConhecimento(
    id,
    date,
    valor,
    historico,
    contaId,
    conhecimentos
  ) {
    return this.axios.put(`/receita/liquidar-conhecimento/${id}`, {
      dtMovimentacao: date,
      valor: valor,
      historico: historico,
      contaId: contaId,
      conhecimentos: conhecimentos,
    });
  }

  receitaLiquidarTotal(id, valor, date, historico, contaId) {
    return this.axios.put(`/receita/liquidar-total/${id}`, {
      dtMovimentacao: date,
      historico: historico,
      contaId: contaId,
      valor,
    });
  }

  createReceita(receita) {
    return this.axios.post(`/receita/create`, receita);
  }

  updateReceita(id, receita) {
    return this.axios.put(`/receita/update/${id}`, receita);
  }

  searchReceita(payload) {
    return this.axios.post(`/receita/grid/search`, payload);
  }

  getReceitaArquivo(tenant, id) {
    return this.axios.get(`/receita/arquivo/${tenant}/${id}`);
  }

  getMultiplasReceitasFatura(tenant, arrayIds) {
    return this.axios.post("/receita/multiplos-arquivos", { arrayIds, tenant });
  }

  getFaturaArquivo(tenant, id) {
    return this.axios.get(`/receita/arquivo/fatura/${tenant}/${id}`);
  }

  getRelatoriosReceita(receita) {
    return this.axios.post(`/receita/relatorios/receita`, receita);
  }

  getReceitaDescontoRelatorio(receita) {
    return this.axios.post(`/receita/relatorios/desconto`, receita);
  }

  estornarReceita(id) {
    return this.axios.put(`/receita/estornar/${id}`);
  }

  gerarRemessaAlteracao(boleto, tenant) {
    return this.axios.post(`/receita/gerar-remessa-alteracao`, {
      boletoId: boleto.id,
      nossoNumero: boleto.nossoNumero,
      numeroDocumento: boleto.numeroDocumento,
      idIntegracao: boleto.idIntegracao,
      cnpjCedente: boleto.cnpjCedente,
      tenant: tenant,
      tituloDataVencimento: boleto.tituloDataVencimento,
      tituloValor: boleto.tituloValor,
    });
  }

  async getIndicatorIncomes(filters = {}) {
    try {
      const response = await this.axios.get(
        `/receita/indicadores/${filters.tenant}`,
        {
          params: {
            ...Api.removeBlankFields(filters),
          },
        }
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  }

  gerarRemessaReceitas(payload) {
    return this.axios.post("/receita/boleto/gerar-remessa-receitas", payload);
  }

  gerarNfsReceitas(payload) {
    return this.axios.post("/receita/nota-servico/gerar-nfs-receitas", payload);
  }

  getReceitaHistorico(id) {
    return this.axios.get(`/receita/historico/${id}`);
  }

  //Boleto
  getBoleto(receitaId) {
    return this.axios.get(`/boleto/arquivo/${receitaId}`);
  }

  async getBoletos(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/boleto/por-tenant/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...filters,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  getLotesBoleto(filters) {
    return this.axios.post("/boleto/get-lotes-boleto/", filters);
  }

  getBoletosLote(payload) {
    return this.axios.post("/boleto/get-boletos-lote/", payload);
  }

  downloadArquivoRemessa(boletoId) {
    return this.axios.get(`/boleto/remessa/${boletoId}`, {
      responseType: "blob",
    });
  }

  incluirBoleto(boleto) {
    return this.axios.post(`/boleto/incluir-boleto`, boleto);
  }

  uploadRetorno(payload) {
    return this.axios.post("/boleto/upload-retorno", payload);
  }

  gerarRemessa(ids, idsIntegracao, cnpjCedente, padraoCnab, tenant) {
    return this.axios.post(`/boleto/gerar-remessa`, {
      ids,
      idsIntegracao,
      cnpjCedente: cnpjCedente,
      padraoCnab,
      tenant: tenant,
    });
  }

  cancelarBoleto(boleto, tenant) {
    return this.axios.post(`/boleto/cancelar-boleto`, {
      id: boleto.id,
      idIntegracao: boleto.idIntegracao,
      cnpjCedente: boleto.cnpj_cedente,
      numeroDocumento: boleto.titulo_numero_documento,
      tenant: tenant,
    });
  }

  getBoletoHistorico(receitaId) {
    return this.axios.get(`/boleto/historico/${receitaId}`);
  }

  listarBancos() {
    return this.axios.get(`/boleto/listar-bancos`);
  }

  getConvenio(tenant, convenioId) {
    return this.axios.get(`/convenio/completo/${tenant}/${convenioId}`);
  }

  getArquivosAlteracao(boleto) {
    return this.axios.get(`/boleto/alteracao/all/${boleto}`);
  }

  baixarArquivoAlteracao(arquivo) {
    return this.axios.get(`/boleto/alteracao/download/${arquivo}`);
  }

  getBoletosByReceitasIds(filters) {
    return this.axios.get("/boleto/get-by-receitas-ids", { params: filters });
  }

  // Centro de Custo

  getCentrosDeCustos(tenant) {
    return this.axios.get(`/centro-de-custo/tenant`, { params: { tenant } });
  }

  getSingleCentroDeCusto(id) {
    return this.axios.get(`/centro-de-custo/show/${id}`);
  }

  createCentroDeCusto(centroDeCusto) {
    return this.axios.post(`/centro-de-custo/create`, centroDeCusto);
  }

  updateCentroDeCusto(id, centroDeCusto) {
    return this.axios.put(`/centro-de-custo/update/${id}`, centroDeCusto);
  }

  centrosDeCustoGridSearch(payload) {
    return this.axios.post("/centro-de-custo/grid/search", payload);
  }

  cancelCentroDeCusto(centroDeCustoId) {
    return this.axios.delete(`/centro-de-custo/cancelar/${centroDeCustoId}`);
  }

  gerarEstruturaPadraoCentroCusto(tenant) {
    return this.axios.post("/centro-de-custo/estrutura-padrao", { tenant });
  }

  // Contas

  getMovimentacaoBancaria(tenant) {
    return this.axios.get(`/conta/movimentacao-bancaria/${tenant}`);
  }

  getMovBancariaByMonth(payload) {
    return this.axios.post(`/conta/movimentacao-bancaria-mes/`, payload);
  }

  getMovimentacaoConta(tenant, contaId) {
    return this.axios.post(`/conta/movimentacao-conta`, {
      tenant,
      contaId: contaId,
    });
  }

  createConta(conta) {
    return this.axios.post(`/conta/create`, conta);
  }

  updateConta(id, conta) {
    return this.axios.put(`/conta/update/${id}`, conta);
  }

  getContas(tenant) {
    return this.axios.post(`/conta/contas-por-tenant`, { tenant });
  }

  getSingleConta(id) {
    return this.axios.get(`/conta/show/${id}`);
  }

  transferChargeBack(movimentacao) {
    return this.axios.post("/conta/transferencia/estornar", movimentacao);
  }

  paymentReport(payload) {
    return this.axios.post("/conta/pagamentos", payload);
  }

  getTransfers(payload) {
    return this.axios.post("/conta/get-transfers", payload);
  }

  // Pessoas
  getParceirosByTenant(payload) {
    return this.axios.post("/pessoa/parceiros-por-tenant", payload);
  }

  async getPessoas(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/pessoa/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...filters,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  async atualizaRemoverFluxoEmail(payload) {
    try {
      const response = await this.axios.put('/pessoa/atualiza-remover-fluxo-email', payload);
      return response;
    } catch (error) {
      return { error };
    }
  }  

  /**
   * @description This method will be deprecated, use getPessoas instead of this
   * @param {String} tenant
   * @param {Bollean} desconsiderarInativos
   */
  getAllPessoas(tenant, desconsiderarInativos) {
    return this.axios.post(`/pessoa/pessoas-por-tenant`, {
      tenant,
      desconsiderarInativos,
    });
  }

  getFornecedores(tenant, inativar) {
    return this.axios.get(
      `/pessoa/fornecedores-por-tenant/${tenant}?inativar=${inativar}`
    );
  }

  getSinglePessoa(id) {
    return this.axios.get(`/pessoa/show/${id}`);
  }

  getPessoasGrid(tenant) {
    return this.axios.get(`/pessoa/pessoas-grid/${tenant}`, { tenant });
  }

  getPessoaCompleta(tenant, id) {
    return this.axios.get(`/pessoa/completa/${tenant}/${id}`);
  }

  getPessoasFiliais(tenant) {
    return this.axios.get(`/pessoa/filiais/${tenant}`);
  }

  getCedentePorTenant(tenant) {
    return this.axios.get(`/pessoa/cedentes-por-tenant/${tenant}`);
  }

  createPessoa(pessoa) {
    return this.axios.post(`/pessoa/create`, pessoa);
  }

  updatePessoa(id, pessoa) {
    return this.axios.put(`/pessoa/update/${id}`, pessoa);
  }

  getPessoaMovimentacoes(id, tenant) {
    return this.axios.get(`/pessoa/movimentacoes/${id}/${tenant}`);
  }

  checkPessoaInativar(id) {
    return this.axios.get(`pessoa/check-inativar/${id}`);
  }

  getPessoaContatos(pessoaIdParam, contatoIdParam, customTenant) {
    const contatoId = contatoIdParam || "";
    return this.axios.get(`/pessoa/${pessoaIdParam}/contato/${contatoId}`, {
      headers: {
        tenant: customTenant,
      },
    });
  }

  async editPessoaContato(pessoaIdParam, contatoId, values) {
    try {
      const response = await this.axios.put(
        `/pessoa/${pessoaIdParam}/contato/${contatoId}`,
        {
          ...values,
        }
      );
      return response;
    } catch (error) {
      return { error };
    }
  }

  async removePessoaContato(idPessoa, idContact) {
    try {
      const response = await this.axios.delete(
        `/pessoa/${idPessoa}/contato/${idContact}`
      );
      return response;
    } catch (error) {
      return { error };
    }
  }

  async createPessoaContato(idPessoa, values) {
    try {
      const response = await this.axios.post(`pessoa/${idPessoa}/contato`, {
        ...values,
      });
      return response;
    } catch (error) {
      return { error };
    }
  }

  getAddressByCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json`);
  }

  getDataByCNPJ(cnpj) {
    return this.axios.get(`/receita-ws/cnpj/${cnpj}`);
  }

  getMatrizByTenant(tenant) {
    return this.axios.get(`/pessoa/matriz-por-tenant/${tenant}`);
  }

  transferMoney(transaction) {
    return this.axios.post("/conta/transferencia/realizar", transaction);
  }

  getRelatoriosPessoa(pessoa) {
    return this.axios.post(`/pessoa/relatorios/pessoa`, pessoa);
  }

  uploadCertificadoDigital(certificado) {
    return this.axios.post(`/pessoa/upload-certificado`, certificado);
  }

  // Conta Orçamentária

  async getContasOrcamentarias(amount = 10, offset = 0, filters) {
    try {
      const response = await this.axios.get(
        `/conta-orcamentaria/${filters.tenant}?offset=${offset}&amount=${amount}`,
        {
          params: {
            ...filters,
          },
        }
      );
      return { data: response.data.data };
    } catch (error) {
      return { error };
    }
  }

  getSingleContaOrcamentaria(id) {
    return this.axios.get(`/conta-orcamentaria/show/${id}`);
  }

  createContaOrcamentaria(contaOrcamentaria) {
    return this.axios.post(`/conta-orcamentaria/create`, contaOrcamentaria);
  }

  updateContaOrcamentaria(id, contaOrcamentaria) {
    return this.axios.put(
      `/conta-orcamentaria/update/${id}`,
      contaOrcamentaria
    );
  }

  cancelContaOrcamentaria(contaOrcamentariaId) {
    return this.axios.delete(
      `/conta-orcamentaria/cancelar/${contaOrcamentariaId}`
    );
  }

  getAllContasOrcamentariaTenant(payload) {
    return this.axios.post("/conta-orcamentaria/all", payload);
  }

  getAllContasOrcamentariasOnlyLastLevel(payload) {
    return this.axios.post("/conta-orcamentaria/only-last-level", payload);
  }

  gerarEstruturaPadraoContaOrcamentaria(tenant) {
    return this.axios.post("/conta-orcamentaria/estrutura-padrao", { tenant });
  }

  importarXlsxContaOrcamentaria(payload) {
    return this.axios.post("/conta-orcamentaria/estrutura-arquivo", payload);
  }

  // Convenios

  getConvenios(tenant) {
    return this.axios.get(`/convenio/convenios-por-tenant/${tenant}`);
  }

  getSingleConvenio(id) {
    return this.axios.get(`/convenio/show/${id}`);
  }

  updateConvenio(id, convenio) {
    return this.axios.put(`/convenio/update/${id}`, convenio);
  }

  getConveniosFatura(filters) {
    return this.axios.get(`/convenio/convenios-fatura`, { params: filters });
  }

  getNextNsuConvenio() {
    return this.axios.get(`/nsu-convenio/next`);
  }

  getContasIntegradas(tenant) {
    return this.axios.get(`/conta/contas-integradas/${tenant}`);
  }

  getTotalRevenuesMonth(tenant) {
    return this.axios.post(`/receita/total-mes`, { tenant });
  }

  getTotalExpensesMonth(tenant) {
    return this.axios.post(`/despesa/total-mes`, { tenant });
  }

  getBalanceAmount(tenant) {
    return this.axios.post(`/conta/saldo-por-tenant`, { tenant });
  }

  getTotalInadimplenciaMonth(tenant) {
    return this.axios.post(`/receita/inadimplencia/total-mes`, { tenant });
  }

  getTotalIncomeDay(tenant) {
    return this.axios.post(`/receita/indicadores/total-dia`, { tenant });
  }

  getTotalExpensesDay(tenant) {
    return this.axios.post(`/despesa/indicadores/total-dia`, { tenant });
  }

  getDashboardChartsInfo(tenant) {
    return this.axios.post(`/dashboard/fluxo-caixa-mes`, { tenant });
  }

  //Despesas
  getDespesasAbertas(amount = 10, offset = 0, filters) {
    return this.axios.get(
      `/despesa/despesas-abertas-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
      {
        params: {
          ...filters,
        },
      }
    );
  }

  getDespesasInadimplentes(amount = 10, offset = 0, filters) {
    return this.axios.get(
      `/despesa/inadimplentes-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
      {
        params: {
          ...filters,
        },
      }
    );
  }

  getDespesasLiquidadas(amount = 10, offset = 0, filters) {
    return this.axios.get(
      `/despesa/liquidadas-grid/${filters.tenant}?offset=${offset}&amount=${amount}`,
      {
        params: {
          ...filters,
        },
      }
    );
  }

  cancelarDespesa(id) {
    return this.axios.put(`/despesa/cancelar/${id}`);
  }

  cancelarReceitaRecorrente(id, date) {
    return this.axios.post(`/despesa-recorrente/cancelar/${id}`, date);
  }

  detalharDespesa(id, tenant) {
    return this.axios.get(`/despesa/detalhar`, {
      params: {
        id: id,
        tenant: tenant,
      },
    });
  }

  detalharDespesaRecorrente(tenant, recorrenteId, vencimento) {
    return this.axios.post(`/despesa-recorrente/detalhar`, {
      tenant,
      recorrenteId,
      vencimento,
    });
  }

  clonarDespesa(payload) {
    return this.axios.post(`/despesa/clonar`, payload);
  }

  searchDespesa(amount = 10, offset = 0, filters) {
    return this.axios.get(
      `/despesa/grid/search/${filters.tenant}?offset=${offset}&amount=${amount}`,
      {
        params: {
          ...filters,
        },
      }
    );
  }

  despesasConcilicacaoGridSearch(payload) {
    return this.axios.post("/despesa/conciliacao-grid/search", payload);
  }

  despesaLiquidarParcial(id, payload) {
    return this.axios.put(`/despesa/liquidar-parcial/${id}`, {
      valor: payload.valor,
      dtMovimentacao: payload.dtMovimentacao,
      historico: payload.historico,
      contaId: payload.contaId,
      despesaValor: payload.despesaValor,
      tenant: payload.tenant,
    });
  }

  despesaLiquidarTotal(id, payload) {
    return this.axios.put(`/despesa/liquidar-total/${id}`, {
      valor: payload.valor,
      dtMovimentacao: payload.dtMovimentacao,
      historico: payload.historico,
      contaId: payload.contaId,
      despesaValor: payload.despesaValor,
      partialOrigin: payload.partialOrigin,
      tenant: payload.tenant,
    });
  }

  recorrenciaLiquidarParcial(id, payload) {
    return this.axios.put(`/despesa-recorrente/liquidar-parcial/${id}`, {
      valor: payload.valor,
      dtMovimentacao: payload.dtMovimentacao,
      historico: payload.historico,
      contaId: payload.contaId,
      mesLiquidacao: payload.mesLiquidacao,
      despesaValor: payload.despesaValor,
      tenant: payload.tenant,
    });
  }

  recorrenciaLiquidarTotal(id, payload) {
    return this.axios.put(`/despesa-recorrente/liquidar-total/${id}`, {
      valor: payload.valor,
      dtMovimentacao: payload.dtMovimentacao,
      historico: payload.historico,
      contaId: payload.contaId,
      mesLiquidacao: payload.mesLiquidacao,
      despesaValor: payload.despesaValor,
    });
  }

  createDespesa(despesa, files) {
    const data = new FormData();
    data.set("despesa", JSON.stringify(despesa));

    files.forEach((file) => data.append("file", file));

    return this.axios.post(`/despesa/create`, data, {
      "Content-Type": "multipart/form-data",
    });
  }

  createDespesaLiquidada(despesa, date, historico, contaId, valor, files) {
    const liquidacao = {
      dtMovimentacao: date,
      historico: historico,
      contaId: contaId,
      valor,
    };
    const payload = { despesa, liquidacao };

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    files.forEach((file) => formData.append("file", file));

    return this.axios.post(`/despesa/create-liquidada`, formData, {
      "Content-Type": "multipart/form-data",
    });
  }

  updateDespesa(id, despesa, files) {
    const data = new FormData();
    data.append("despesa", JSON.stringify(despesa));

    files.forEach((file) => data.append("file", file));

    return this.axios.put(`/despesa/update/${id}`, data, {
      "Content-Type": "multipart/form-data",
    });
  }

  getRelatoriosDespesa(despesa) {
    return this.axios.post(`/despesa/relatorios/despesa`, despesa);
  }

  getDespesaDescontoRelatorio(despesa) {
    return this.axios.post(`/despesa/relatorios/desconto`, despesa);
  }

  downloadDespesaAnexo(filename, despesaId) {
    return this.axios.post(
      "/despesa/anexo",
      {
        despesaId: despesaId,
        file: filename,
      },
      { responseType: "blob" }
    );
  }

  estornarPagamentoDespesa(id, tenant) {
    return this.axios.put(`/despesa/estornar/${id}/${tenant}`);
  }

  expenseSuggestion(payload) {
    return this.axios.post("/despesa/sugestao", payload);
  }

  unificarDespesas(payload) {
    return this.axios.post("/despesa/unificar-despesas", payload);
  }

  checkExpenseDuplicity(payload) {
    return this.axios.post("/despesa/check-duplicity", payload);
  }

  getDespesaHistorico(id) {
    return this.axios.get(`/despesa/historico/${id}`);
  }

  // Conhecimento
  getConhecimentosAFaturar(conhecimento) {
    return this.axios.post(`/conhecimento/a-faturar`, conhecimento);
  }

  getConhecimentosTomador(filters) {
    return this.axios.post("/conhecimento/por-tomador", filters);
  }

  getConhecimentosALiquidar(payload) {
    return this.axios.post("/conhecimento/a-liquidar", payload);
  }

  getRelatoriosConhecimentos(conhecimento) {
    return this.axios.post(
      `/conhecimento/relatorios/conhecimento`,
      conhecimento
    );
  }

  liquidarConhecimentos(payload) {
    return this.axios.post("/conhecimento/liquidar-fatura", payload);
  }

  processarArquivoXlsxCte(payload) {
    return this.axios.post("/conhecimento/processar-arquivo", payload);
  }

  // Cidade

  getCidades(amount = 10, offset = 0, filters) {
    return this.axios.get(
      `/cidade/get-cidades-paginated?offset=${offset}&amount=${amount}`,
      { params: { ...filters } }
    );
  }

  getSingleCidade(id) {
    return this.axios.get(`/cidade/show/${id}`);
  }

  createCidade(cidade) {
    return this.axios.post("/cidade/create", cidade);
  }

  updateCidade(id, cidade) {
    return this.axios.put(`/cidade/update/${id}`, cidade);
  }

  getUfs() {
    return this.axios.get("/uf/all");
  }

  getSingleUf(id) {
    return this.axios.get(`/uf/show/${id}`);
  }

  createUf(uf) {
    return this.axios.post("/uf/create", uf);
  }

  updateUf(id, uf) {
    return this.axios.put(`/uf/update/${id}`, uf);
  }

  //Grupos faturamento
  getSingleGruposFaturamento(id) {
    return this.axios.get(`/grupo-faturamento/show/${id}`);
  }

  getGruposFaturamento(tenant) {
    return this.axios.post("/grupo-faturamento/get-all", { tenant });
  }

  createGrupoFaturamento(payload) {
    return this.axios.post("/grupo-faturamento/create", payload);
  }

  updateGrupoFaturamento(id, payload) {
    return this.axios.put(`/grupo-faturamento/update/${id}`, payload);
  }

  // Grupos pessoa
  getSingleGrupoPessoa(id) {
    return this.axios.get(`/grupo-pessoa/show/${id}`);
  }

  getGruposPessoa(tenant) {
    return this.axios.post("/grupo-pessoa/get-all", { tenant });
  }

  createGrupoPessoa(payload) {
    return this.axios.post("/grupo-pessoa/create", payload);
  }

  updateGrupoPessoa(id, payload) {
    return this.axios.put(`/grupo-pessoa/update/${id}`, payload);
  }

  deleteGrupoPessoa(id) {
    return this.axios.delete(`/grupo-pessoa/delete/${id}`);
  }

  // Dashboard
  dashboardTotalByMonth(tenant) {
    return this.axios.post("/dashboard/movimentacao-por-mes", { tenant });
  }

  // Motivo bloqueio
  getMotivosBloqueio(tenant) {
    return this.axios.get(`/motivo-bloqueio/tenant/${tenant}`);
  }

  getSingleMotivoBloqueio(id) {
    return this.axios.get(`/motivo-bloqueio/show/${id}`);
  }

  createMotivoBloqueio(motivo) {
    return this.axios.post("/motivo-bloqueio/create", motivo);
  }

  updateMotivoBloqueio(id, motivo) {
    return this.axios.put(`/motivo-bloqueio/update/${id}`, motivo);
  }

  bloqueioConhecimentoGrid(payload) {
    return this.axios.post(`/conhecimento/conhecimentos-a-bloquear`, payload);
  }

  bloquearConhecimentos(payload) {
    return this.axios.put(`/conhecimento/bloquear`, payload);
  }

  desbloquearConhecimento(payload) {
    return this.axios.put(`/conhecimento/desbloquear`, payload);
  }

  //Agrupamentos Demonstração
  getAgrupamentosDemonstracao(tenant) {
    return this.axios.post("/agrupamento-demonstracao/all", {
      tenant,
    });
  }

  getSingleAgrupamentoDemonstacao(id, tenant) {
    return this.axios.get(`/agrupamento-demonstracao/single/${tenant}/${id}`);
  }

  createAgrupamentoDemonstacao(agrupamento) {
    return this.axios.post(`/agrupamento-demonstracao/create`, agrupamento);
  }

  updateAgrupamentoDemonstacao(id, agrupamento) {
    return this.axios.put(
      `/agrupamento-demonstracao/update/${id}`,
      agrupamento
    );
  }

  //Emquadramento Conta
  getEnquadramentos(tenant) {
    return this.axios.post("/enquadramento-conta/all", {
      tenant,
    });
  }

  getEnquadramentosTotais(payload) {
    return this.axios.post(
      "/enquadramento-conta/enquadramentos-totais",
      payload
    );
  }

  singleEnquadramento(id) {
    return this.axios.get(`/enquadramento-conta/single/${id}`);
  }

  createEnquadramentoConta(enquadramento) {
    return this.axios.post(`/enquadramento-conta/create`, enquadramento);
  }

  updateEnquadramentoConta(id, enquadramento) {
    return this.axios.put(`/enquadramento-conta/update/${id}`, enquadramento);
  }

  getDREInfo(payload) {
    return this.axios.post("/dre/dre-info", payload);
  }

  gerarEstruturaPadraoDRE(tenant) {
    return this.axios.post("/dre/estrutura-padrao", { tenant });
  }

  estornarReceitaCte(payload) {
    return this.axios.put("/receita/estornar-por-cte/", payload);
  }

  criarConvenio(payload) {
    return this.axios.post("/convenio/create", payload);
  }

  // planos
  criarPlano(payload) {
    return this.axios.post("/plano/create", payload);
  }

  getPlano(id) {
    return this.axios.get(`/plano/show/${id}`);
  }

  updatePlano(id, plano) {
    return this.axios.put(`/plano/update/${id}`, plano);
  }

  getPlanos(tenant) {
    return this.axios.get(`/plano/tenant/${tenant}`);
  }

  getClientesPorPlano(payload) {
    return this.axios.post("/plano/clientes-por-plano", payload);
  }

  atualizarValorPlanos(payload) {
    return this.axios.post("/plano/atualizar-valor-planos", payload);
  }

  // conciliacao
  sendOfxFile(payload) {
    return this.axios.post("/conciliacao/processar-arquivo", payload);
  }

  conciliacaoPorPeriodo(payload) {
    return this.axios.post("/conciliacao/por-periodo", payload);
  }

  excluirMovimentacoes(payload) {
    return this.axios.post("/conciliacao/excluir-movimentacoes", payload);
  }

  editarRegistroConciliacao(id, payload) {
    return this.axios.put(`/conciliacao/update/${id}`, payload);
  }

  undoReconciliation(id, payload) {
    return this.axios.put(`/conciliacao/undo/${id}`, payload);
  }

  // NFS-e
  findNfseByField(field, value, tenant) {
    return this.axios.post("/nota-fiscal-servico/find-by-field", {
      field,
      value,
      tenant,
    });
  }

  // Arquivos
  getRemessas(filters) {
    return this.axios.post("/remessa/get-remessas/", filters);
  }

  downloadRemessa(payload) {
    return this.axios.post("/remessa/download", payload);
  }

  getFaturasRemessa(payload) {
    return this.axios.post("/remessa/faturas", payload);
  }

  /**
   * @description Receives an object and removes blank strings and empty arrays
   * @param {Object} fields
   */
  static removeBlankFields(fields = {}) {
    const finalObject = {};
    Object.keys(fields).forEach((field) => {
      if (
        fields[field] &&
        (fields[field].length || typeof fields[field] === "number")
      ) {
        finalObject[`${field}`] = fields[field];
      }
    });
    return finalObject;
  }

  // Faturamento por Plano
  getPlanosAfaturarPeriodo(dataInicio, dataFim, tenant) {
    return this.axios.post("/faturamento-por-plano/listar", {
      dataInicio,
      dataFim,
      tenant,
    });
  }

  faturarPorPlano(payload) {
    return this.axios.post("/faturamento-por-plano/faturar", payload);
  }

  visualizarFaturamentoPorPlano(payload) {
    return this.axios.post("/faturamento-por-plano/visualizar-faturamento", payload);
  }

  planosPorPessoa(sacadoId, tenant, dataInicio, dataFim) {
    return this.axios.post("/faturamento-por-plano/listar-por-sacado", {
      sacadoId,
      tenant,
      dataInicio,
      dataFim,
    });
  }

  faturamentoPorPlanoReportXlsx(payload) {
    return this.axios.post("/faturamento-por-plano/visualizacao-xlsx", payload);
  }

  faturamentoPorPlanoReportPdf(payload) {
    return this.axios.post("/faturamento-por-plano/visualizacao-pdf", payload);
  }

  // Protocolo Fatura
  getProtocolosFatura(tenant) {
    return this.axios.post("/protocolo-fatura/get-by-tenant", { tenant });
  }

  getProtocoloFatura(id) {
    return this.axios.get(`/protocolo-fatura/show/${id}`);
  }

  createProtocoloFatura(protocoloFatura) {
    return this.axios.post("/protocolo-fatura/create", protocoloFatura);
  }

  updateProtocoloFatura(id, protocoloFatura) {
    return this.axios.put(`/protocolo-fatura/update/${id}`, protocoloFatura);
  }

  baixarProtocolo(id, protocoloFatura) {
    return this.axios.post(`/protocolo-fatura/baixar/${id}`, protocoloFatura);
  }

  getPdfProcolo(id) {
    return this.axios.get(`/protocolo-fatura/imprimir/${id}`);
  }

  // static
  downloadModeloLiquidacaoCte() {
    return this.axios.get("/static/modelo-liquidar-cte");
  }

  // disponibilidade
  getDisponibilidades(payload) {
    return this.axios.post("/disponibilidade/get-disponibilidades", payload);
  }

  getDisponibilidadeFiliais(tenant) {
    return this.axios.get(`/disponibilidade/get-filiais/${tenant}`);
  }

  getRomaneiosAFaturar(filters) {
    return this.axios.get("/disponibilidade/get-romaneios-faturar", {
      params: {
        ...filters,
      },
    });
  }

  getRomaneiosVeiculo(filters) {
    return this.axios.get("/disponibilidade/get-romaneios-veiculo", {
      params: {
        ...filters,
      },
    });
  }

  faturarDisponibilidades(payload) {
    return this.axios.post("/disponibilidade/faturar-disponibilidade", payload);
  }

  // MensagemEmail

  getMensagensEmail() {
    return this.axios.get(`/mensagem-email/all`);
  }

  getSingleMensagemEmail(id) {
    return this.axios.get(`/mensagem-email/show/${id}`);
  }

  createMensagemEmail(mensagemEmail) {
    return this.axios.post("/mensagem-email/create", mensagemEmail);
  }

  updateMensagemEmail(id, mensagemEmail) {
    return this.axios.put(`/mensagem-email/update/${id}`, mensagemEmail);
  }

  getMensagemPadrao() {
    return this.axios.get(`/mensagem-email/padrao`);
  }

  deleteMensagemEmail(id) {
    return this.axios.delete(`/mensagem-email/delete/${id}`);
  }

  // Relatórios

  getRelatorioConhecimentosXlsx(payload) {
    return this.axios.post(`/relatorios/conhecimentos/xlsx`, payload);
  }

  getRelatorioReceitaXlsx(payload) {
    return this.axios.post(`/relatorios/receitas/xlsx`, payload);
  }

  getRelatorioDespesaXlsx(payload) {
    return this.axios.post(`/relatorios/despesas/xlsx`, payload);
  }

  getRelatorioMovimentacaoXlsx(payload) {
    return this.axios.post(`/relatorios/movimentacoes/xlsx`, payload);
  }

  getRelatorioPagamentoXlsx(payload) {
    return this.axios.post("/relatorios/pagamentos/xlsx", payload);
  }

  getNotaFiscalXlsx(payload) {
    return this.axios.post("/relatorios/nota-fiscal/xlsx", payload);
  }

  getRelatorioPessoaXlsx(payload) {
    return this.axios.post(`/relatorios/pessoas/xlsx`, payload);
  }

  getRelatorioPessoaPdf(payload) {
    return this.axios.post(`/relatorios/pessoas/pdf`, payload);
  }

  getRelatorioDespesaDescontoXlsx(payload) {
    return this.axios.post(`/relatorios/despesas-descontos/xlsx`, payload);
  }

  getRelatorioReceitaDescontoXlsx(payload) {
    return this.axios.post(`/relatorios/receitas-descontos/xlsx`, payload);
  }

  // indicadores saas
  getCac(filters) {
    return this.axios.get("/indicadores-saas/cac", {
      params: {
        ...filters,
      },
    });
  }

  getMrr(filters) {
    return this.axios.get("/indicadores-saas/mrr", {
      params: {
        ...filters,
      },
    });
  }

  getArr(filters) {
    return this.axios.get("/indicadores-saas/arr", {
      params: {
        ...filters,
      },
    });
  }

  getTicketMedio(filters) {
    return this.axios.get("/indicadores-saas/ticket-medio", {
      params: {
        ...filters,
      },
    });
  }
  getLtv(filters) {
    return this.axios.get("/indicadores-saas/ltv", {
      params: {
        ...filters,
      },
    });
  }

  getChurnRate(filters) {
    return this.axios.get("/indicadores-saas/churn-rate", {
      params: {
        ...filters,
      },
    });
  }

  getGrowthRate(filters) {
    return this.axios.get("/indicadores-saas/growth-rate", {
      params: {
        ...filters,
      },
    });
  }

  getMrrRate(filters) {
    return this.axios.get("/indicadores-saas/mrr-rate", {
      params: {
        ...filters,
      },
    });
  }

  getCashBurnRunway(filters) {
    return this.axios.get("/indicadores-saas/cash-burn-runway", {
      params: {
        ...filters,
      },
    });
  }

  // Nota Serviço

  createNotaServico(payload) {
    return this.axios.post("/nota-servico/create", payload);
  }

  editNotaServico(id, payload) {
    return this.axios.put(`/nota-servico/update/${id}`, payload);
  }

  deleteNotaServico(id) {
    return this.axios.delete(`/nota-servico/delete/${id}`);
  }

  getAllNotasServico(filters) {
    return this.axios.get(`/nota-servico`, {
      params: {
        ...filters,
      },
    });
  }

  createNotaServicoHistorico(id, payload) {
    return this.axios.post(`/nota-servico-historico/${id}/create`, payload);
  }

  enviarNfsPrefeitura(municipio, ambiente, provider, xml, payload) {
    return this.axios.post(`/nota-servico/prefeitura-enviar`, payload, {
      headers: {
        municipio,
        ambiente,
        provider,
        xml,
      },
    });
  }

  cancelarNfsPrefeitura(municipio, ambiente, provider, xml, payload) {
    return this.axios.post(`/nota-servico/prefeitura-cancelar`, payload, {
      headers: {
        municipio,
        ambiente,
        provider,
        xml,
      },
    });
  }

  consultarNfsPrefeitura(municipio, ambiente, provider, xml, payload) {
    return this.axios.post(`/nota-servico/prefeitura-consultar`, payload, {
      headers: {
        municipio,
        ambiente,
        provider,
        xml,
      },
    });
  }

  nfsSequencial(id) {
    return this.axios.get(`/pessoa/nfs-sequencial-rps/${id}`);
  }

// #region E-mail Template
createEmailTemplate(payload) {
  return this.axios.post("/email-template/create", payload);
}

editEmailTemplate(id, payload) {
  return this.axios.put(`/email-template/update/${id}`, payload);
}

getEmailTemplateById(id) {
  return this.axios.get(`/email-template/show/${id}`);
}

deleteEmailTemplate(id) {
  return this.axios.delete(`/email-template/delete/${id}`);
}

getAllEmailTemplate(filters) {
  return this.axios.get(`/email-template/all`, {
    params: {
      ...filters,
    },
  });
}

enviarEmailTemplate(payload) {
  return this.axios.post(`/email-template/send-email`, payload);
}
// #endregion E-mail Template

// #region Regua Cobrança
createReguaCobranca(payload) {
  return this.axios.post("/regua-cobranca/create", payload);
}

editReguaCobranca(id, payload) {
  return this.axios.put(`/regua-cobranca/update/${id}`, payload);
}

getReguaCobrancaById(id) {
  return this.axios.get(`/regua-cobranca/show/${id}`);
}

deleteReguaCobranca(id) {
  return this.axios.delete(`/regua-cobranca/delete/${id}`);
}

getAllReguaCobranca(filters) {
  return this.axios.get(`/regua-cobranca/all`, {
    params: {
      ...filters,
    },
  });
}
// #endregion Regua Cobrança

// #region Bloqueio de Cliente
createBloqueioCliente(payload) {
  return this.axios.post("/bloqueio-cliente/create", payload);
}

editBloqueioCliente(id, payload) {
  return this.axios.put(`/bloqueio-cliente/update/${id}`, payload);
}

getBloqueioClienteById(id) {
  return this.axios.get(`/bloqueio-cliente/show/${id}`);
}

deleteBloqueioCliente(id) {
  return this.axios.delete(`/bloqueio-cliente/delete/${id}`);
}

getAllBloqueioCliente(filters) {
  return this.axios.get(`/bloqueio-cliente/all`, {
    params: {
      ...filters,
    },
  });
}
// #endregion  Bloqueio de Cliente

// #region Integrações Externas
createIntegracaoExterna(payload) {
  return this.axios.post("/integracoes-externas/create", payload);
}

editIntegracaoExterna(id, payload) {
  return this.axios.put(`/integracoes-externas/update/${id}`, payload);
}

getIntegracaoExternaById(id) {
  return this.axios.get(`/integracoes-externas/show/${id}`);
}

deleteIntegracaoExterna(id) {
  return this.axios.delete(`/integracoes-externas/delete/${id}`);
}

getAllIntegracaoExternas(filters) {
  return this.axios.get(`/integracoes-externas/all`, {
    params: {
      ...filters,
    },
  });
}

async getAllPessoaIntegracoesExternas(tenant) {
  return this.axios.get(`/pessoa-integracoes-externas/matriz/${tenant}`);
}
// #endregion Integrações Externas
}