import React from 'react';
import Api from '../../config/Api';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap';
import Cookies from 'js-cookie';
import dashboardRoutes from 'routes/dashboard.jsx';
import SearchPerson from '../SearchPerson/SearchPerson';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: 'transparent',
      pessoaSelected: '',
      companies: [],
      companiesList: [],
      selectedCompany: null,
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);

  }

  componentWillMount() {
    this.api = new Api(this.context.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
      return Promise.reject(err);
    });
  }

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'dark',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getBrand() {
    let name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else if (prop.redirect) {
        if (prop.path === this.props.location.pathname) {
          name = prop.name;
        }
      } else if (prop.path === this.props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }

  openSidebar() {
    document.documentElement.classList.toggle('nav-open');
    this.refs.sidebarToggle.classList.toggle('toggled');
  }

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'dark',
      });
    } else {
      this.setState({
        color: 'transparent',
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this));
    this.getCompaniesList();
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993
      && e.history.location.pathname !== e.location.pathname
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      this.refs.sidebarToggle.classList.toggle('toggled');
    }
  }

  logout = () => {
    Cookies.remove('FINANCEIRO_TOKEN',{ domain: process.env.REACT_APP_COOKIE_DOMAIN })
    Cookies.remove('PESSOA_SEARCH',{ domain: process.env.REACT_APP_COOKIE_DOMAIN })
    window.removeEventListener('resize', this.updateColor.bind(this));
    this.props.history.push({ pathname: '/login' });
  }


  getCompaniesList() {
    const { companies, setTenantContext } = this.context;

    if (companies) {
      const companiesList = companies.map((company) => ({ 
        value: company.id, 
        label: company.name 
      }))

      const selectedCompany = Cookies.getJSON('SELECTED_COMPANY') || null;

      if (!selectedCompany) {
        Cookies.set('SELECTED_COMPANY', companiesList[0] || null, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      }

      const selectedComp = selectedCompany 
      ? selectedCompany.label 
      : companiesList[0] 
        ? companiesList[0].label 
        : ''

      setTenantContext(selectedComp)
      Cookies.set('TENANT_PAINEL', selectedComp, { 
            domain: process.env.REACT_APP_COOKIE_DOMAIN 
          }
      );

      this.setState({
        companies,
        companiesList,
        selectedCompany: selectedCompany || companiesList[0],
        dataLoaded: true,
      });
    }
  }

  handleCompanySelection = async (event) => {
    const { setTenantContext } = this.context;
    if (event) {
      setTenantContext(event.label)

      Cookies.set('SELECTED_COMPANY', event, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      Cookies.set('TENANT_PAINEL', event.label, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      this.setState({ selectedCompany: event }, () => {
        window.location.reload();
      });
    }
  }

  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'dark'
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'navbar-absolute fixed-top zero-padding'
            : `navbar-absolute fixed-top zero-padding ${this.state.color === 'transparent' ? 'navbar-transparent ' : ''}`
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
          <SearchPerson
            tenant={this.context.tenant}
            api={this.api}
          />
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <div className="header-block">
              <p className={`header-greetings ${this.state.isOpen ? 'header-white' : ''} `}>
                Bem vindo,
                {' '}
                {this.context.fullname}
              </p>
              <div className="header-company-select">
                <Select
                  menuPosition="fixed"
                  placeholder="Selecione"
                  className="company-select"
                  options={this.state.companiesList}
                  onChange={this.handleCompanySelection}
                  value={this.state.selectedCompany}
                  defaultValue={this.state.selectedCompany}
                />
              </div>
              <button onClick={this.logout} className='logout-button'>
                <i className="fas fa-sign-out-alt">
                  <p>Sair</p>
                </i>
              </button>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;

Header.contextType = AuthContext;