import React, { useState } from 'react';
import {
  Button,
  Col, Form, FormGroup, Input, Row,
} from 'reactstrap';
import logo from '../../cargozilla.svg';

export default function RecoveryPassword(props) {
  const [username, setUsername] = useState(props.username);
  const [codigo, setCodigo] = useState('');
  const [etapa, setEtapa] = useState('SEND_EMAIL');
  const [senha, setSenha] = useState('');
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (etapa === 'SEND_EMAIL') {
      const { data: response } = await props.api.sendConfirmationCodeEmail(username);
      if (response.status) {
        setEmail(response.email);
        return setEtapa('CODE');
      }
    }

    if (etapa === 'CODE') {
      const { data: response } = await props.api.validateConfirmationCode(username, codigo);
      if (response.status) {
        return setEtapa('NEW_PASSWORD');
      }
    }

    if (etapa === 'NEW_PASSWORD') {
      const { data: response } = await props.api.recoveryUsernamePassword(username, senha);
      if (response.status) {
        props.abrirNotificacaoSucesso();
        return props.toggleRecoveryModal();
      }
    }
  };

  const handleBlur = () => {
    if (senha === confirmaSenha) {
      setIsValid(true);
    } else {
      props.abrirNotificacaoErro('As senha digitadas não são iguais. Digite novamente');
      setSenha('');
      setConfirmaSenha('');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col className="card-info">
          <div className="login-logo">
            <div className="app-login">
              <div className="logo-img">
                <img src={logo} alt="react-logo" className="logo-login" />
              </div>
            </div>
          </div>
          <p className="recovery-text">Não se preocupe, estamos aqui para ajudá-lo a recuperar sua senha.</p>
        </Col>
      </Row>
      <Row className="card-info">
        <Col md="5">
          {etapa === 'SEND_EMAIL' && (
            <FormGroup>
              <Input
                type="text"
                name="username"
                id="username"
                value={username}
                placeholder="Digite seu nome de usuário"
                onChange={(e) => setUsername(e.target.value)}
                required
                minLength="3"
              />
              <Button
                className="w-100"
                color="success"
                type="submit"
                disabled={!username}
              >
                Recuperar
              </Button>
            </FormGroup>
          )}
          {etapa === 'CODE' && (
            <FormGroup>
              <p className="recovery-message">Digite o código recebido em seu e-mail:</p>
              <p className="recovery-message">{email}</p>
              <Input
                type="text"
                name="codigo"
                id="codigo"
                value={codigo}
                placeholder="Digite aqui seu código"
                onChange={(e) => setCodigo(e.target.value)}
                required
                maxLength="6"
              />
              <Button
                className="w-100"
                color="success"
                type="submit"
                disabled={!codigo}
              >
                Confirmar
              </Button>
            </FormGroup>
          )}
          {etapa === 'NEW_PASSWORD' && (
            <FormGroup>
              <p className="recovery-message">Digite sua nova senha</p>
              <Input
                type="password"
                name="senha"
                id="senha"
                value={senha}
                placeholder="Digite sua nova senha"
                onChange={(e) => setSenha(e.target.value)}
                required
              />
              <br />
              <Input
                type="password"
                name="confirmaSenha"
                id="confirmaSenha"
                value={confirmaSenha}
                placeholder="Digite novamente sua nova senha"
                onChange={(e) => setConfirmaSenha(e.target.value)}
                onBlur={() => handleBlur()}
                required
              />
              <br />
              <Button
                className="w-100"
                color="success"
                type="submit"
                disabled={!isValid}
              >
                Salvar
              </Button>
            </FormGroup>
          )}
        </Col>
      </Row>
    </Form>
  );
}
