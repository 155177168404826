import axios from 'axios';
const controleUsuariosApi = process.env.REACT_APP_CONTROLE_DE_USUARIOS_URL;

export default class ApiControleUsuario {
  constructor(token, route, onError) {
    this.axios = axios.create({
      baseURL: controleUsuariosApi,
      headers: {
        Authorization: token,
        Route: route,
      }
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  createUserHistory(payload) {
    return this.axios.post(`/users/access-item`, payload);
  }

}
